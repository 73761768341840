/**
 * XXX this should be moved into evmgr styles when everything works fine
 */

/**
 * SingleEvent select box
 */
.single-event-select {
	padding-top: 10px;
}

.navbar-evmgr ul.navbar-right {
	position: absolute;
	top: 0;
	right: 0;
	background-color: @color;
}
