







































































































.autosuggest__results {
  position: absolute;
  z-index: 1000;
  background-color: white;
  padding: 0;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);

  ul {
    padding: 5px 0;
    margin: 0;
    list-style: none;

    li {
      .suggestion-item {
        display: block;
        padding: 3px 20px;

        &:hover {
          background-color: #a3bcc2;
          cursor: pointer;
        }
      }
    }
  }
}
