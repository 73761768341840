/**
 * Bootstrap 3 compatible checkbox switch styling
 *
 * adapted from:
 *
 * Project : CSS Checkbox Switch
 * Author : Partha Kar (https://www.facebook.com/partha.creativemind)
 * Version : 1.0
 * Release Dtae : 15 November, 2017
 */

.checkbox.checkbox-switch {
    padding-left: 0;
}

.checkbox.checkbox-switch label,
.checkbox-inline.checkbox-switch {
    display: inline-block;
    position: relative;
    padding-left: 0;
	text-align:left;
}
.checkbox.checkbox-switch label input,
.checkbox-inline.checkbox-switch input {
    display: none;
}
.checkbox.checkbox-switch label span,
.checkbox-inline.checkbox-switch span {
    width: 35px;
    border-radius: 20px;
    height: 18px;
    border: 1px solid #dbdbdb;
    background-color: rgb(255, 255, 255);
    border-color: rgb(223, 223, 223);
    box-shadow: rgb(223, 223, 223) 0px 0px 0px 0px inset;
    transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
}
.checkbox.checkbox-switch label span:before,
.checkbox-inline.checkbox-switch span:before {
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: rgb(255,255,255);
    content: " ";
    top: 0;
    position: relative;
    left: 0;
    transition: all 0.3s ease;
    box-shadow: 0 1px 4px rgba(0,0,0,0.4);
}
.checkbox.checkbox-switch label > input:checked + span:before,
.checkbox-inline.checkbox-switch > input:checked + span:before {
    left: 17px;
}


/* Switch Default */
.checkbox.checkbox-switch label > input:checked + span,
.checkbox-inline.checkbox-switch > input:checked + span {
    background-color: rgb(180, 182, 183);
    border-color: rgb(180, 182, 183);
    box-shadow: rgb(180, 182, 183) 0px 0px 0px 8px inset;
    transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s, background-color 1.2s ease 0s;
}
.checkbox.checkbox-switch label > input:checked:disabled + span,
.checkbox-inline.checkbox-switch > input:checked:disabled + span {
    background-color: rgb(220, 220, 220);
    border-color: rgb(220, 220, 220);
    box-shadow: rgb(220, 220, 220) 0px 0px 0px 8px inset;
    transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s, background-color 1.2s ease 0s;
}
.checkbox.checkbox-switch label > input:disabled + span,
.checkbox-inline.checkbox-switch > input:disabled + span {
    background-color: rgb(232,235,238);
    border-color: rgb(255,255,255);
}
.checkbox.checkbox-switch label > input:disabled + span:before,
.checkbox-inline.checkbox-switch > input:disabled + span:before {
    background-color: rgb(248,249,250);
    border-color: rgb(243, 243, 243);
    box-shadow: 0 1px 4px rgba(0,0,0,0.1);
}

/* Switch Light */
.checkbox.checkbox-switch.switch-light label > input:checked + span,
.checkbox-inline.checkbox-switch.switch-light > input:checked + span {
    background-color: rgb(248,249,250);
    border-color: rgb(248,249,250);
    box-shadow: rgb(248,249,250) 0px 0px 0px 8px inset;
    transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s, background-color 1.2s ease 0s;
}

/* Switch Dark */
.checkbox.checkbox-switch.switch-dark label > input:checked + span,
.checkbox-inline.checkbox-switch.switch-dark > input:checked + span {
    background-color: rgb(52,58,64);
    border-color: rgb(52,58,64);
    box-shadow: rgb(52,58,64) 0px 0px 0px 8px inset;
    transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s, background-color 1.2s ease 0s;
}
.checkbox.checkbox-switch.switch-dark label > input:checked:disabled + span,
.checkbox-inline.checkbox-switch.switch-dark > input:checked:disabled + span {
    background-color: rgb(100, 102, 104);
    border-color: rgb(100, 102, 104);
    box-shadow: rgb(100, 102, 104) 0px 0px 0px 8px inset;
    transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s, background-color 1.2s ease 0s;
}

/* Switch Success */
.checkbox.checkbox-switch.switch-success label > input:checked + span,
.checkbox-inline.checkbox-switch.switch-success > input:checked + span {
    background-color: rgb(40, 167, 69);
    border-color: rgb(40, 167, 69);
    box-shadow: rgb(40, 167, 69) 0px 0px 0px 8px inset;
    transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s, background-color 1.2s ease 0s;
}
.checkbox.checkbox-switch.switch-success label > input:checked:disabled + span,
.checkbox-inline.checkbox-switch.switch-success > input:checked:disabled + span {
    background-color: rgb(153, 217, 168);
    border-color: rgb(153, 217, 168);
    box-shadow: rgb(153, 217, 168) 0px 0px 0px 8px inset;
}

/* Switch Danger */
.checkbox.checkbox-switch.switch-danger label > input:checked + span,
.checkbox-inline.checkbox-switch.switch-danger > input:checked + span {
    background-color: rgb(200, 35, 51);
    border-color: rgb(200, 35, 51);
    box-shadow: rgb(200, 35, 51) 0px 0px 0px 8px inset;
    transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s, background-color 1.2s ease 0s;
}
.checkbox.checkbox-switch.switch-danger label > input:checked:disabled + span,
.checkbox-inline.checkbox-switch.switch-danger > input:checked:disabled + span {
    background-color: rgb(216, 119, 129);
    border-color: rgb(216, 119, 129);
    box-shadow: rgb(216, 119, 129) 0px 0px 0px 8px inset;
    transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s, background-color 1.2s ease 0s;
}

/* Switch Primary */
.checkbox.checkbox-switch.switch-primary label > input:checked + span,
.checkbox-inline.checkbox-switch.switch-primary > input:checked + span {
    background-color: rgb(0, 105, 217);
    border-color: rgb(0, 105, 217);
    box-shadow: rgb(0, 105, 217) 0px 0px 0px 8px inset;
    transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s, background-color 1.2s ease 0s;
}
.checkbox.checkbox-switch.switch-primary label > input:checked:disabled + span,
.checkbox-inline.checkbox-switch.switch-primary > input:checked:disabled + span {
    background-color: rgb(109, 163, 221);
    border-color: rgb(109, 163, 221);
    box-shadow: rgb(109, 163, 221) 0px 0px 0px 8px inset;
    transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s, background-color 1.2s ease 0s;
}

/* Switch Info */
.checkbox.checkbox-switch.switch-info label > input:checked + span,
.checkbox-inline.checkbox-switch.switch-info > input:checked + span {
    background-color: rgb(23, 162, 184);
    border-color: rgb(23, 162, 184);
    box-shadow: rgb(23, 162, 184) 0px 0px 0px 8px inset;
    transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s, background-color 1.2s ease 0s;
}
.checkbox.checkbox-switch.switch-info label > input:checked:disabled + span,
.checkbox-inline.checkbox-switch.switch-info > input:checked:disabled + span {
    background-color: rgb(102, 192, 206);
    border-color: rgb(102, 192, 206);
    box-shadow: rgb(102, 192, 206) 0px 0px 0px 8px inset;
    transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s, background-color 1.2s ease 0s;
}

/* Switch Warning */
.checkbox.checkbox-switch.switch-warning label > input:checked + span,
.checkbox-inline.checkbox-switch.switch-warning > input:checked + span {
    background-color: rgb(255, 193, 7);
    border-color: rgb(255, 193, 7);
    box-shadow: rgb(255, 193, 7) 0px 0px 0px 8px inset;
    transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s, background-color 1.2s ease 0s;
}
.checkbox.checkbox-switch.switch-warning label > input:checked:disabled + span,
.checkbox-inline.checkbox-switch.switch-warning > input:checked:disabled + span {
    background-color: rgb(226, 195, 102);
    border-color: rgb(226, 195, 102);
    box-shadow: rgb(226, 195, 102) 0px 0px 0px 8px inset;
    transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s, background-color 1.2s ease 0s;
}

