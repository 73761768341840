.nav-tabs {
    border: none;
    margin: 0 auto;
    display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;      /* TWEENER - IE 10 */
    display: -webkit-flex;     /* NEW - Chrome */
    display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */
    text-align: center;
    position: relative;
   
   &:before {
       content: "";
       display: block;
       border-bottom: 1px solid @divider;
       position: absolute;
       bottom: 0;
   }
   &>li {
       margin-bottom: -1px;
       position: relative;
       z-index:1;
       background: #fff;
       border: 1px solid @divider;
       border-right: none;
       -webkit-box-flex: 1;      /* OLD - iOS 6-, Safari 3.1-6 */
       -moz-box-flex: 1;         /* OLD - Firefox 19- */
       width: 20%;               /* For old syntax, otherwise collapses. */
       -webkit-flex: 1;          /* Chrome */
       -ms-flex: 1;              /* IE 10 */
      flex: 1;  
     
       &>a {
           .border-radius(0);
           margin-right: 0px;
           color: @grey;
           padding: 15px 30px;
           font-size: 14px;
           background: lighten(@smoky-white, 2%);
           border: none;
           
           .fa {
               font-size: 20px;
           }
           
           &:hover {
               background: @smoky-white;
           }
           
       }
       &.active {
           border-top-color: @color;
           border-bottom-color: #fff;
           &>a {
               color: @color;
               background: #fff;
               .box-shadow(inset 0 3px 0 @color);
               border: none;
               &:hover, &:focus {
                   border: none;
               }
           }
           &:hover>a {
               border-top: 0;
               border: none;
               color: @color;
           }
       }
       &.last {
           border-right: 1px solid @divider;
       }
       
   }  
     
}

