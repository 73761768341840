/** navbar */
.navbar-default {
	background-color: #b0d6ff;
	border-color: #76b8ff;
/*	background-image: none; */
}
.navbar-default .navbar-brand {
	color: #5b5b5b;
	padding: 7px 15px;
}
.navbar-default .navbar-brand:hover, .navbar-default .navbar-brand:focus {
	color: #3c3c3c;
}
.navbar-default .navbar-text {
	color: #5b5b5b;
}
.navbar-default .navbar-nav > li > a {
	color: #5b5b5b;
}
.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
	color: #3c3c3c;
}
.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
	color: #3c3c3c;
	background-color: #76b8ff;
/*	background-image: none;
	box-shadow: none;*/
}
.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
	color: #3c3c3c;
	background-color: #76b8ff;
}
.navbar-default .navbar-toggle {
	border-color: #76b8ff;
}
.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
	background-color: #76b8ff;
}
.navbar-default .navbar-toggle .icon-bar {
	background-color: #5b5b5b;
}
.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
	border-color: #5b5b5b;
}
.navbar-default .navbar-link {
	color: #5b5b5b;
}
.navbar-default .navbar-link:hover {
	color: #3c3c3c;
}

@media (max-width: 767px) {
	.navbar-default .navbar-nav .open .dropdown-menu > li > a {
		color: #5b5b5b;
	}
	.navbar-default .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
		color: #3c3c3c;
	}
	.navbar-default .navbar-nav .open .dropdown-menu > .active > a, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
		color: #3c3c3c;
		background-color: #76b8ff;
	}
}

/** navbar split dropdown */
.navbar-nav > li.dropdown-split-left > a {
    padding-right: 0;
}
.navbar-nav > li.dropdown-split-right > a {
    padding-left: 7px;
}

/** content margin-top */
.jumbotron-alert .jumbotron {
	margin-top: 15px;
}

/** checkbox min-height is not there in docs...??? */
.checkbox, .radio {
	min-height: unset;
}
