/* ======= Header ======= */
.header { 
    color: #fff;
    width: 100%;  
    position: relative;
    z-index: 40;
    height: 80px;
    
    &.navbar-fixed-top {        
        height: 80px;
        top: 0;
        .main-nav .nav .nav-item a {
            color: @text-color-secondary;
            .opacity(1); 
        }  
        .main-nav .nav .nav-item.nav-item-cta a.btn-cta-secondary {
            background: @color;
            border-color: @color;
        }
        .main-nav .nav .dropdown-menu a {
            color: #fff;
        }

    }

    h1.logo {
        font-family: @font-family-fat;
        margin-top: 0; 
        margin-bottom: 0;
        font-weight: bold;
        font-size: 15px;       
        float: left;
        
        a {
            color: #fff;
            padding: 15px 30px;
            display: inline-block;
            text-align: center;
            line-height: 1.4;
            max-width: 155px;
            background: @color;
            .border-radiuses(0, 4px, 4px, 0);  
            .transition (color 0.4s ease-in-out);  
            position: relative;
            font-size: 20px;
            
            &:hover {    
                text-decoration: none;     
            }
        }
        .logo-title {
            vertical-align: middle;
            line-height: 1.6;
        }
    }
    
    .main-nav {
        margin-top: 15px;
        .navbar-toggle {
            margin-right: 0;
            margin-top: 0;
            background: none;
            position: absolute;
            right:10px;
            top: 10px;
            
            &:focus {
                outline: none;
            }
            .icon-bar {
                background-color: rgba(256, 256, 256, 0.6);
                height: 3px;
            }
            &:hover {
                .icon-bar {
                    background-color: #fff;
                }
            }
        }
        
        .nav {
            .nav-item {
                font-weight: normal;
                text-transform: uppercase;
                font-size: 13px;
                margin-right: 15px;
                
                &.active {
                    a {
                        color: @color;
                        background: none;
                        font-weight: bold;
                    }
                }
                &.nav-item-cta {
                    a.btn-cta-secondary {
                        border: 1px solid #fff;
                        color: #fff;
                        padding: 8px 10px;
                        margin-top: 5px;
                        font-size: 13px;
                        &:hover {
                            border: 1px solid @color;
                            background: @color;
                        }
                    }
                }
                a {
                    color: @main-nav-link-color;
                    &:hover {  
                        color: @color;    
                        background: none;         
                    }
                    &:focus {
                        outline: none;
                        background: none;
                    }
                    &:active {
                        outline: none;
                        background: none;
                    }
                }    
                &.active {
                    color: @color;             
                }
                &.last {
                    margin-right: 0;
                }
            }

            &.active {
                position: relative;
                & > a {
                    background: darken(@color, 10%);
                    color: #fff;
                    color: rgba(256, 256, 256, 0.8);
                    &:before {
                        content: "\f0d8";
                        position: absolute;
                        bottom: -8px;
                        left: 0;
                        text-align: center;
                        font-family: FontAwesome;    
                        display: block;
                        font-size: 12px;
                        width: 100%;                      
                    }   
                    &.dropdown-toggle:before {
                        display: none;
                    }
                }                      
            }
            &.open {
                a {
                    background: none;
                }
            }
            .dropdown-menu {
                .border-radius(4px);
                margin: 0;
                border:none;
                text-transform: none;
                min-width: 180px;
                background: @color-2;       
                
                &:before {
                    content: "";
                    display: block;
                    width: 0;
                    height: 0;
                    border-left: 10px solid transparent;
                    border-right: 10px solid transparent;
                    border-bottom: 10px solid @color-2;
                    position: absolute;
                    right: 30px;
                    top: -10px; 
                }
                
                a {
                    border-bottom: 1px solid darken(@color-2, 2%);
                    padding: 8px 20px;
                    color: #fff;    
                    .opacity(1);               
                    
                    &:hover {
                        background: lighten(@color-2, 5%);
                        color: #fff;
                    }
                }
            }
            
        }
    }
    
}

