/** jstree overrides */
#tree-container li a {
	white-space: normal;
	width: 800px;
}
#tree-container li a .pull-right {
	position: absolute;
	left: 400px;
}
#tree-container.jstree-classic .jstree-clicked {
	background-color: #0088CC;
	border: none;
}
#tree-container.jstree-classic ins.pull-right {
	background: none;
	width: auto;
}


/**
 * highlight functionality
 */
.highlight {
    background-color: yellow;
}

/**
 * mark required fields with asterisk
 */
.form-group.required .control-label:after {
  content:" *";
  color:#a94442;
}


/** make dt wider */
@media (min-width: 768px) {
  .dl-horizontal dt {
    float: left;
    width: 260px;
    clear: left;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .dl-horizontal dd {
    margin-left: 280px;
  }
}



/**
 * body padding for fixed navbar should be set in layout 
 */
body {
	/* padding-top: 40px; */
}

.pointer {
	cursor: pointer;
}



/**
 * centered tables
 */
.table-centered{
  margin: 0 auto !important;
  float: none !important;
}

/**
 * definition list table (align top, right) 
 */
.table-dl {
	width: 100%;
}
.table-dl th, .table-dl td {
	vertical-align: top;
}
.table-dl th {
	text-align: right;
    width: 30%;
}

/** firefox hack for responsive tables (weird fieldset layouting) */
/** removed after rendering bug has been fixed in Firefox 86.
@-moz-document url-prefix() {
  fieldset { display: table-cell; }
}
*/

/** make table-responsive always active */
.table-responsive {
    border: 1px solid #dddddd;
    margin-bottom: 15px;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
}

/** spin icons */
.spin{
     -webkit-transform-origin: 50% 58%;
     transform-origin:50% 58%;
     -ms-transform-origin:50% 58%; /* IE 9 */
     -webkit-animation: spin .2s infinite linear;
     -moz-animation: spin .2s infinite linear;
     -o-animation: spin .2s infinite linear;
     animation: spin .2s infinite linear;
}








.radio-checkbox-brace-anchor {
	position: absolute;
	left: -28px;
	top: 0;
	padding: 2px;
}
.radio-checkbox-brace-anchor canvas {
	position: relative;
	z-index: 1000;
}
.btn.radio-checkbox-new-group {
	margin: 7px 25px;
}

.shadow-box{
	/* IE */
	zoom: 1;
	/* For IE 8 */
	-ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=4, Direction=135, Color='#CCCCCC')";
	/* For IE 5.5 - 7 */
	filter: progid:DXImageTransform.Microsoft.Shadow(Strength=4, Direction=135, Color='#CCCCCC');
	/* khtml, old safari */
	-webkit-box-shadow: 2px 2px 9px #CCCCCC;
	/* mozilla, netscape */
	-moz-box-shadow: 2px 2px 9px #CCCCCC;
	/* standard CSS */
	box-shadow: 2px 2px 9px #CCCCCC;
}


/**
 * custom fields editor stuff
 */
.template {
	display: none !important;
}
.fieldset-editable > .row > .form-group {
	margin-left: 41px;
    margin-right: 15px;
    padding: 5px 0px;
	position: relative;
	border: 1px solid rgba(0,0,0,0);
	min-height: 44px;
}
.editable-overlay {
    position: absolute;
    top: 0;
	display:none;
	width: 100%;
	padding: 10px;
    z-index: 1;
    cursor: pointer;
}
/** when bootstro is active we simply reset our and bootstrap's active btn z-index */
.fieldset-editable.bootstro-active .editable-overlay,
.fieldset-editable.bootstro-active .editable-overlay .btn.active {
	z-index: auto;
}
/** ...but bootstrap-highlight needs the z-index again.. */
.fieldset-editable.bootstro-active .editable-overlay .btn.active.bootstro-highlight {
	z-index: 9999;
}
.fieldset-editable > .row > .form-group:hover,
.fieldset-editable > .row > .form-group.editing,
.fieldset-editable > .row > .form-group.bootstro-highlight {
	background-color: #e0efff;
	border: 1px solid #ccc;
    border-radius: 3px;
    box-shadow: 2px 2px 9px #CCCCCC;
}
.form-group.editing .editable-overlay,
.form-group:hover .editable-overlay {
	display: block;
}
.editable-edit-form {
    border-top: 1px solid #ccc;
    padding-top: 5px;
}

/*
.fieldset-editable > .row > .form-group.radio-checkbox {
	margin-left: 25px;
}
.fieldset-editable > .row > .form-group.radio-checkbox > *:first-child {
    margin-left: -10px;
}
*/

.form-vertical .form-group > label {
  text-align: left;
}
.form-vertical .form-group {
	margin-left: 0;
	margin-right: 0;
}
.form-vertical .form-group.col-sm-offset-3 {
	margin-left: 25%;
}
.form-horizontal .form-vertical .form-group > label {
  float: none;
  padding-top: 0;
  text-align: left;
}
/*!
 * this important!
 */
.row > .form-group {
	margin-left: 0;
	margin-right: 0;
}
/*
.form-horizontal .form-vertical .controls {
  margin-left: 0;
}
.form-horizontal .form-vertical.form-actions,
.form-horizontal .form-vertical .form-actions {
  padding-left: 20px;
}
*/

/*
.form-group .form-group {
  margin-bottom: 0;
}
*/


/**
 * special styles for printing
 */
@media print {
	a[href]:after {
		content: "";
	}
	.navbar {
		display: block !important;
		position: absolute !important;
	}
	.navbar .nav {
		display: none;
	}
	.page-break {
		page-break-before: always;
	}
}
