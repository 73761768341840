/**
 * navbar design
 */
.navbar-fixed-top {
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
}



/**
 * let scrollbar always be there
 */
html {
	overflow-y: scroll;
}

/** legend inside fieldset.row */
.row legend {
	margin-left: 15px;
	margin-right: 15px;
	width: 48%;
}

.radio-group .control-label {
    width: 100%;
}

/** responsive tables should not waste space below... */
.table-responsive > table {
	margin-bottom: 0;
}


/** username-check */
.username-check {
	min-height: 25px;
}


/** actions header */
.actions h3 {
	font-size: 0.8em;
	float: left;
}
.actions {
	margin-bottom: 10px;
}



/** but bootstrap uses class arrow too */
.popover .arrow {
	top: auto;
}

fieldset.well > legend {
	background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 2px;
    margin-bottom: 0;
    padding: 0 7px;
    width: auto;
}

/* BEGIN filter tabs and panes */
.filter-form .nav-tabs {
	margin-bottom: 0; /* makes for a nicer box together with tab-pane */
	border-bottom: 0;
}
.filter-form {
	margin-bottom: 20px;
}
.filter-form .filter-buttons {
	display: inline-block;
	white-space: nowrap;
	position: absolute;
}
.filter-buttons.btn-group .btn {
    float: none;
}

.action-panel .nav-pills {
	margin-bottom: 0;
}
.action-panel .nav-pills a {
	padding: 7px 10px;
}

/** try to override bootstrap accordion transitions */
/*
.collapse {
	transition: height 0.01s;
}
*/

/*
.sums table th {
	text-align: right;
	padding: 0 5px 0 20px;
}
*/


.filter-panes, #filterSummary, .filter-form .nav-tabs > .active > a, .filter-form .nav-tabs > .active > a:hover {
	background-color: #F9F9F9;
}
/* END filterTabs */

/* flag filter */
.flagCheckSet .logicalOperatorAnd {
	display: block;
}
.flagCheckSet {
	white-space: nowrap;
}
.flagCheckSet label, .flagCheckSet span, .flagCheckSet input {
	display: inline;
	padding: 2px;
}
#flagCheckSetButton {
	margin-bottom: 3px;
}

/* BEGIN misc adjustments */
.action-menu {
	margin: 0 20px 0 0;
}
/*
.action-menu ul {
	margin: 5px;
}
.action-panel > ul {
	margin-bottom: 0;
}
.btn-group > li {
	list-style-type: none;
}
i.icon-phone {
	width: 14px;
	height: 14px;
	background-image: url('../../../img/phone_14.png');
	background-position: 0;
}
.table-condensed th, .table-condensed td {
	padding: 1px;
}
*/


/* overriding bootstrap-editable */
td.editable {
	border-bottom: 1px solid #dddddd;
	cursor: pointer;
}

/*
#columns label {
    margin-left: 3px;
    display: inline-block;
}


.form .input.date select {
	width: auto;
}
form .submit-top {
	position: relative;
}
form .submit-top input {
	position: absolute;
	right: 0;
	text-align: right;
	top: -40px;
}

fieldset.no-labels .control-group label {
    display: none;
}
input[type=checkbox].inline {
	margin: 4px 5px 15px 5px;
	float: left;
}
input[type=checkbox].inline.label-before {
	float: none;
	margin: 9px 5px 10px;
}

.attend .controls-row {
	margin-bottom: 7px;
}
*/
/* in attend we don't want that much margin */
/*
.attend input[type=checkbox].inline {
	margin-bottom: 7px;
}
*/

/*
.signup.form legend {
    font-size: 16px;
	font-weight: bold;
    padding: 17px 0 0;
    margin-bottom: 5px;
	line-height: 25px;
}
.signup.menu .submit {
    float: left;
}

.center {
    text-align: center;
}
.centered-block, .controls-row .centered-block, .row-fluid .controls-row .centered-block {
	display: block;
	float: none;
	margin-left: auto;
	margin-right: auto;
}
.full-width, input.full-width {
	width: 100%;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: content-box;
	-ms-box-sizing: content-box;
	box-sizing: content-box;
}

.vert-spacer {
    margin-top: 30px;
}

.dl-horizontal dt {
    width: 280px;
}
.dl-horizontal dd {
    margin-left: 300px;
}


fieldset.ui-sortable {
	border: 3px solid red;
}
 */


/* prevent composed divs from having to much margin */
/*
div[class*="span"] > div[class*="span"] {
	margin-left: 0;
}
 */
 
/*
.signup fieldset.address legend .btn {
	font-weight: normal;
}
.signup fieldset.address legend {
	position: relative;
}
.signup fieldset.address legend > .btn-group {
	position: absolute;
	left: 35%;
}
.signup fieldset.address a.saved-address {
	cursor: pointer;
}
*/






/**
 * superscript editor description fine tuning
 */
.superscriptDesc {
	padding: 4px 6px;
	min-width: 225px;
}


/**
 * validation error msg positioning
 */
.form-group.has-error .text-danger {
	display: block;
	clear: both;
}

.select-all-label input {
	margin-right: 5px;
}
.select-all-label {
	font-weight: normal;
	font-size: 85%;
}
.select-all-label:hover {
	text-decoration: underline;
}


/**
 * lowercase formatting only when field is filled (valid)
 */
.form-control.lowercase:valid {
	text-transform: lowercase;
}


/**
 * visible columns details
 */
#column-selector .panel-body .col-sm-4 {
    border-left: 1px solid #ccc;
}


/** bootstro: hide step number because we change it */
.popover .popover-inner .popover-title span.label.label-success {
    display: none;
}
.bootstro-backdrop {
	opacity: 0.3;
}
.form-group.bootstro-highlight {
	background-color: white;
}
.bootstro-highlight, .btn.active.bootstro-highlight {
	outline: 5px solid white;
}


/** navbar form */
.navbar-form .form-group {
    vertical-align: top;
}
/** xs inputs for navbar */
.input-xs {
	border-radius: 3px;
    font-size: 11px;
    height: 24px;
    padding: 3px 5px;
    border: 1px solid #a0a0eb;
}
.navbar-form .help-block {
	margin: 1px 6px 0;
	line-height: 11px;
	font-size: 9px;
}

/** screenshot carousel */
.carousel.screenshots {
}
.carousel.screenshots .carousel-control {
	background-image: none;
	color: #b5b5b5;
}
.carousel.screenshots .carousel-caption {
	background-color: rgba(255, 255, 255, 0.6);
	border-radius: 2px;
	left: 40%;
    padding: 5px;
    right: 15%;
    bottom: 20%;
    color: #4d4d4d;
    text-align: right;
}
.carousel.screenshots .carousel-indicators {
	margin-bottom: 0;
	bottom: 0;
}
.carousel.screenshots .carousel-indicators li {
	border-color: #b5b5b5;
}
.carousel.screenshots .carousel-indicators .active {
	background-color: #b5b5b5;
}


.content-maxwidth {
	max-width: 1200px;
	margin: auto;
}
